import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO
        title="About"
        keywords={[`gpt-2`, `Jakob Anderson`, `Text Generation`, `blog`]}
      />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2>Writing the dreams of an insane AI</h2>
          <Img fluid={data.about.childImageSharp.fluid} className="kg-image" />
          <br />

          <p>
            <strong>"Eclectic Beams"</strong> is a take on "Electric Dreams",
            referring to the psychedelic artificial intelligence concepts of the
            writer Philip K. Dick. Eclectic Beams is a 2020 lockdown project by
            me, Jakob Anderson. I decided to make use of the time I had at home,
            and learn text generation concepts in machine learning, and to write
            a program that could generate children's bedtime stories and read
            them aloud, all automatically.{" "}
          </p>

          <p>
            The project started by using the GPT-2 model and a{" "}
            <a href="https://colab.research.google.com/drive/1VLG8e7YSEwypxU-noRNhsv5dW4NfTGce">
              google colab notebook
            </a>
            , and the stories ended up weird and disjointed. Not really fare for
            children, but the adults I've read these stories to said they were
            "spooky", so I doubled down on spookiness and strangeness in my
            reinforcements. I publish the stories just as the AI wrote them,
            only adding formatting. The name "Eclectic Beams" comes from my
            wife, Liz, who said "It's like the AI is beaming out its' strange
            and original thoughts".
          </p>

          <p>
            Right now, I do a lot of curating, reading 100 or more extremely
            unusable stories it generates, just to find each of these only
            slightly insane ones. I hope to improve the workflow and model more
            over time to get more consistent results.
          </p>

          <p>
            Any connection to existing fictional or real characters, people,
            trademarks, or whatever are probably incidental side-effects of the
            machine learning models being trained on publicly available text on
            the internet. I try to avoid references to known things, but I'm
            presenting the stories just as the AI writes them, with only
            selectional curation of results per generation, usually. If you or
            your works are incidentally mentioned in a story here, I'd like to
            hear of it. These magical black boxes we call ML models are surely
            strange, and I'd love to learn how it might have "thought" of your
            works in its' insane dreams. I don't claim any ownership over the
            copyright or trademark of provably pre-existing works that occur in
            these stories.
          </p>

          <h5>Phrases and Idioms I commonly use to seed story generation:</h5>
          <ul>
            <li>
              <a href="https://randomwordgenerator.com/sentence.php">
                This random word generator
              </a>
            </li>
            <li>
              <a href="https://fungenerators.com/random/idiom">
                This random idiom generator
              </a>
            </li>
            <li>
              <a href="https://www.theidioms.com/">This idiom Collection</a>
            </li>
            <li>
              <a href="https://www.be-a-better-writer.com/cliches.html">
                This cliche' collection
              </a>
            </li>
            <li>
              <a href="https://gist.githubusercontent.com/signed0/d70780518341e1396e11/raw/2a7f4af8d181a714f9d49105ed57fafb3f450960/quotes.json">
                This quotes dataset gist
              </a>
            </li>
            <li>
              <a href="https://www.phrases.org.uk/meanings/phrases-and-sayings-list.html">
                This phrase list
              </a>
            </li>
          </ul>

          <p>
            You can find this project on twitter at{" "}
            <a href="https://twitter.com/eclecticbeams">@eclecticbeams</a>
          </p>

          <p>
            You can find my other works at{" "}
            <a href="https://jakobanderson.com/about">jakobanderson.com</a>
          </p>
        </div>

        <hr />

        <span>
          Photo by{" "}
          <a href="https://unsplash.com/@photoshobby?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">
            Photos Hobby
          </a>{" "}
          on{" "}
          <a href="https://unsplash.com/s/photos/robot?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">
            Unsplash
          </a>
        </span>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    about: file(relativePath: { eq: "photos-hobby-JjGXjESMxOY-unsplash.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
